import React, {useEffect} from "react";
import {Navigate, Routes, Route} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {Global, css} from '@emotion/react'

import theme from './theme'
import Login from './components/login';
import PatientsReports from './components/patients-reports';
// import VersionFooter from "./components/common/footar";
import serviceCall from "./reducers/serviceCall";
import Constants from "./utils/constants";
import Requisition from "./components/requisition";
import ClientsReports from "./components/clients-reports";

function App() {
    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        try {
            pingServer()
        } catch (e) {
        }
    }, []);

    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    function pingServer() {
        serviceCall({
            url: Constants.API.PING,
            method: 'GET',
        });
    }

    /**
     * End Methods
     */

    return (
        <ThemeProvider theme={theme}>
            <Global
                styles={css`
                  html, body, #root {
                    height: 100%;
                    margin: unset;
                    background-color: #F4F4F4;
                  }
                `}
            />
            <Routes>
                <Route exact path={Constants.ROUTES.LOGIN} element={<Login/>}/>
                <Route exact path={Constants.ROUTES.REPORT} element={<PatientsReports/>}/>
                <Route exact path={Constants.ROUTES.REQUISITION} element={<Requisition/>}/>
                <Route exact path={Constants.ROUTES.PATIENTS_REPORT} element={<ClientsReports/>}/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
            <div id='snackbarhelper'></div>
            {/* TODO uncomment this before production */}
            {/* {process.env.REACT_APP_ENV !== 'production' && <VersionFooter/>} */}
        </ThemeProvider>
    );
}

export default App;
