import React, {useRef, useState} from 'react';
import {
    Container,
    HistoryIcon,
    IconButton,
    SwipeableDrawer,
    Typography
} from '../../ui';
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import ClientsReportsMobileView from "./clientsReportsMobileView";
import clientsReportsDrawerColumns from "./clientsReportsDrawerColumns";
import DataGridTable from "../common/dataGridTable";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPatientsReportsHistory,
    getLoading,
    getPatientsReportsHistory
} from "./clientsReportsReducer";
import ClientsReportsGrid from "./clientsReportsGrid";
import {ClientsReportsHistoryWrapper} from "./style";

const sortModelDefault = {
    field: 'coldate',
    sort: 'desc',
}

const drawerBleeding = 56;

export default function ViewClientReportHistory(props) {
    /**
     * Start Initials
     */
    const {report} = props

    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(5)

    const dispatch = useDispatch();
    //used to get reports from getReports reducer action
    const reports = useSelector(getPatientsReportsHistory);
    //used to get loading type (data fetching done or not)
    const loading = useSelector(getLoading);
    /**
     * End Initials
     */

    /**
     * Start Methods
     */
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    function openDrawer(){
        dispatch(fetchPatientsReportsHistory(report));
        toggleDrawer(true)
        setOpen(true)
    }
    /**
     * End Methods
     */

    return (
        <ClientsReportsHistoryWrapper>
            <IconButton color="success" aria-label="View Report History" component="label" className="viewHistoryBtn"  onClick={openDrawer}>
                <HistoryIcon className="respIcon"/>
                <Typography className="respIcon">
                    &nbsp;View History
                </Typography>
            </IconButton>
            <SwipeableDrawer
                disableBackdropTransition
                disableDiscovery={true}
                anchor='bottom'
                swipeAreaWidth={drawerBleeding}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Container sx={isMobile ? {height: window.innerHeight/2, pt:2} : {}}>
                    <BrowserView style={{marginLeft: '32px', marginRight: '-32px'}}>
                        <DataGridTable
                            columns={clientsReportsDrawerColumns}
                            reports={reports}
                            total={reports.length}
                            loading={loading}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            paginationMode='client'
                        />
                    </BrowserView>

                    <MobileView>
                        <div className="mobileScrollDiv">
                            {reports.map((report, key) => (
                                <ClientsReportsGrid key={`${key}`} report={report} drawermodel={1}/>
                            ))}
                            {loading && <Typography variant='h5'>Fetching reports...</Typography>}
                        </div>
                    </MobileView>
                </Container>
            </SwipeableDrawer>
        </ClientsReportsHistoryWrapper>
    );
}