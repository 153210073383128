import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {downloadPatientReport, getPatientReportBuffer} from './clientsReportsReducer';
import {Button, Link} from './../../ui'
import toast from "../../utils/toast";
import {base64toBlob} from "../../utils";
import PdfViewer from "../common/pdfViewer";

export default function DownloadClientReport(props) {
    /**
     * Start Initials
     */
    const {reportId, isButton, filename} = props;

    let [downloading, setDownloading] = useState(false);
    let [openModal, setOpenModal] = useState(false)
    let [pdf, setPdf] = useState('')

    const dispatch = useDispatch();
    const patientReportBuffer = useSelector(getPatientReportBuffer);
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        downloadReport();
    }, [patientReportBuffer]);
    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    function downloadReport() {
        if (patientReportBuffer && downloading) {

            //return error if not array
            if (!Array.isArray(patientReportBuffer)){
                toast.error("Report not found");
                return;
            }

            //return error if array is empty
            if (patientReportBuffer.length < 0){
                toast.error("Report not found");
                return;
            }

            setPdf(window.btoa(
                patientReportBuffer[0].data.reduce((data, byte) => data + String.fromCharCode(byte), '')
            ));
            setOpenModal(true);
            setDownloading(false)
        }
    }

    function viewReport() {
        dispatch(downloadPatientReport(reportId));
        setDownloading(true);
    }
    /**
     * End Methods
     */

    return (
        <>
            {isButton ?
                <Button variant="contained" className="downloadReportBtn" onClick={viewReport}>Download</Button> :
                <Link className="downloadReportLink" onClick={viewReport}>Download</Link>}

            <PdfViewer pdf={pdf} fileName={filename} openModal={openModal} setOpenModal={setOpenModal}/>
        </>
    )
}