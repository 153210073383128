import React from 'react'
import { LoadingButton, TextField } from "../../ui";
import Recaptcha from "./recaptcha";

export default function RequestOTP(props) {
    /**
     * Start Initials
     */
    const { updateUser, user, loading, captchaResponse, setCaptchaResponse, errors} = props;
    /**
     * End Initials
     */

    /**
     * Start Methods
     */
    function onEmailPhoneInputChange(e) {
        updateUser(e.target.value, 'userLoginId')
    }
    /**
     * End Methods
     */

    return (
        <>
            <TextField className="emailInput" required id="outlined-basic" label="Enter Email Address / Mobile Number"
                onChange={onEmailPhoneInputChange}
                name='email_phone'
                error={!!errors?.userLoginId}
                helperText={errors?.userLoginId}
                variant="outlined" />
            <div className="captcha">
                <Recaptcha onChange={setCaptchaResponse}/>
            </div>
            {/*<LoadingButton disabled={!user?.userLoginId || !!errors.email_phone} fullWidth*/}
            <LoadingButton disabled={!user?.userLoginId || !captchaResponse} fullWidth
                loading={loading}
                type="submit"
                className="actionButton"
                variant="contained">
                Lookup Results
            </LoadingButton>
        </>
    )
}