import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {downloadPatientReport, getPatientReportBuffer} from './patientsReportReducer';
import {Button, Link} from './../../ui'
import toast from "../../utils/toast";
import VerifyIdentityDialog from "../common/verifyIdentityDialog";
import {PatientsReportDownloadWrapper} from "./style";
import PdfViewer from "../common/pdfViewer";

export default function DownloadPatientReport(props) {
    /**
     * Start Initials
     */
    let [downloading, setDownloading] = useState(false)
    let [dialog, setDialog] = useState(false)
    let [search, setSearch] = useState('')
    let [openModal, setOpenModal] = useState(false)
    let [pdf, setPdf] = useState('')
    const [dateValue, setDateValue] = useState(null)
    const [nameValue, setNameValue] = useState('')

    const {reportId, orderId, isButton, filename} = props;

    const dispatch = useDispatch();
    const patientReportBuffer = useSelector(getPatientReportBuffer);
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        downloadReport()
    }, [patientReportBuffer]);

    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    function viewReport() {
        setDialog(false)
        dispatch(downloadPatientReport(reportId, orderId, search));
        setDownloading(true)
    }

    function downloadReport() {
        if (patientReportBuffer && downloading) {
            //return error if not array
            if (!Array.isArray(patientReportBuffer)) {
                toast.error("Report not found");
                return;
            }

            //return error if array is empty
            if (patientReportBuffer.length < 0) {
                toast.error("Report not found");
                return;
            }

            setPdf(window.btoa(
                patientReportBuffer[0].data.reduce((data, byte) => data + String.fromCharCode(byte), '')
            ));
            setOpenModal(true);

            setDownloading(false)
        }

        setSearch('')
        setNameValue('')
        setDateValue(null)
    }

    function openDialog() {
        setDialog(true)
    }

    /**
     * End Methods
     */

    return (
        <PatientsReportDownloadWrapper>
            {isButton ?
                <Button variant="contained" className='downloadReportBtn' onClick={openDialog}>Download</Button> :
                <Link className='downloadReportLink' onClick={openDialog}>Download</Link>
            }
            <VerifyIdentityDialog dialog={dialog} setDialog={setDialog} setSearch={setSearch} submitData={viewReport} setDateValue={setDateValue} setNameValue={setNameValue} dateValue={dateValue} nameValue={nameValue}/>

            <PdfViewer pdf={pdf} fileName={filename} openModal={openModal} setOpenModal={setOpenModal}/>
        </PatientsReportDownloadWrapper>
    )
}
