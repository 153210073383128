import React, {useState} from 'react';
import {
    Container,
    HistoryIcon,
    IconButton,
    Paper,
    SwipeableDrawer,
    Typography
} from '../../ui';
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {fetchPatientsReportsHistory, getLoading, getPatientsReportsHistory} from "./patientsReportReducer";
import DataGridTable from "../common/dataGridTable";
import PatientsReportsGrid from "./patientsReportsGrid";
import patientsReportsDrawerColumns from "./patientsReportsDrawerColumns";
import {PatientsReportsHistoryWrapper} from "./style";

export default function ViewPatientReportHistory(props) {
    /**
     * Start Initials
     */
    const {report} = props

    const [pageSize, setPageSize] = useState(5)
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    //used to get reports from getPatientsReports reducer action
    const patientsReportsHistory = useSelector(getPatientsReportsHistory);
    //used to get loading type (data fetching done or not)
    const loading = useSelector(getLoading);
    /**
     * End Initials
     */

    /**
     * Start Methods
     */
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    function openDrawer(){
        dispatch(fetchPatientsReportsHistory(report))
        toggleDrawer(true)
        setOpen(true)
    }
    /**
     * End Methods
     */

    return (
        <PatientsReportsHistoryWrapper>
            <IconButton color="success" aria-label="View Report History" component="label" sx={{ml: 1}}
                        onClick={openDrawer}>
                <HistoryIcon className='respIcon'/>
                <Typography className='respIcon'>&nbsp;View History</Typography>
            </IconButton>
            <SwipeableDrawer
                disableBackdropTransition
                disableDiscovery={true}
                anchor='bottom'
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Container sx={isMobile ? {height: window.innerHeight / 2, pt: 2} : {}}>
                    <BrowserView style={{marginLeft: '32px', marginRight: '-32px'}}>
                        <DataGridTable
                            columns={patientsReportsDrawerColumns}
                            reports={patientsReportsHistory}
                            loading={loading}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                            total={patientsReportsHistory.length}
                            paginationMode='client'
                            filterMode='client'
                        />
                    </BrowserView>

                    <MobileView>
                        {patientsReportsHistory.length > 0 ? patientsReportsHistory.map(report => <PatientsReportsGrid
                                key={report.id} report={report} drawermodel={1}/>) :
                            <Paper>
                                <Typography textAlign='center' className="noRecordDiv">
                                    No record found
                                </Typography>
                            </Paper>}
                    </MobileView>
                </Container>
            </SwipeableDrawer>
        </PatientsReportsHistoryWrapper>
    );
}