import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchPatientsReports, getLoading, getPatientsReports, getPatientsReportsTotal} from "./clientsReportsReducer";
import {createUrlParams} from "../../utils";
import ClientsReportsGrid from "./clientsReportsGrid";
import {Box, Stack, TextField, Typography} from "../../ui";
import {Autocomplete} from "@mui/lab";
import {getLocalData} from "../../utils/storageManager";

const pageSize = 10;

export default function ClientsReportsMobileView(props) {
    /**
     * Start Initials
     */
    const {sortModelDefault, report} = props;

    const [sitesList, setSitesList] = useState([])
    const [site, setSite] = useState('')
    const [listItems, setListItems] = useState([]);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);

    const listInnerRef = useRef();

    const dispatch = useDispatch();
    const reports = useSelector(getPatientsReports);
    const total = useSelector(getPatientsReportsTotal);
    const loading = useSelector(getLoading);
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        getSites();
    }, []);

    useEffect(() => {
        setListItems([...listItems, ...reports]);
    }, [reports]);

    useEffect(() => {
        if (site) dispatch(fetchPatientsReports(pageSize, page, createUrlParams(sortModelDefault), search, site, report));
    }, [site]);

    useEffect(() => {
        loadMoreReports();
    }, [search]);

    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    function getSites() {
        try {
            const sitesData = JSON.parse(getLocalData('sites'));

            let siteArr = [];
            sitesData.forEach((v)=>{
                siteArr.push(v);
            });
            setSitesList(siteArr);
            if(siteArr.length > 0)
                setSite(siteArr[0] ?? '');
        } catch {
        }
    }

    function loadMoreReports() {
        if (listItems.length >= total && !searching) return;

        setSearching(false);
        setPage(page + 1);
        dispatch(fetchPatientsReports(pageSize, page, createUrlParams(sortModelDefault), search, site, report));
    }

    function onScroll() {
        if (listInnerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                loadMoreReports();
            }
        }
    }

    function searchReports(e) {
        setPage(0);
        setListItems([]);
        setSearching(true);
        setSearch(e.target.value);
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            searchReports(e);
        }
    }

    function onChangeSite(newValue) {
        setPage(0);
        setListItems([]);
        setSearching(true);
        setSite(newValue);
    }

    /**
     * End Methods
     */

    return (
        <>
            <Box sx={{width: '100%'}}>
                <Stack direction="row" spacing={1} sx={{mb: 1}}>
                    <Autocomplete
                        disableClearable
                        autoSelect
                        options={sitesList}
                        value={site}
                        fullWidth={true}
                        onChange={(event, newValue) => {
                            onChangeSite(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Sites"/>}
                    />
                </Stack>
                <TextField fullWidth label="Search" variant="outlined" onBlur={searchReports}
                           onKeyDown={handleKeyDown} className="searchInput"/>
            </Box>
            <div onScroll={() => onScroll()} ref={listInnerRef} className="mobileScrollDiv">
                {listItems.map((report, key) => (
                    <ClientsReportsGrid key={`${page}-${key}`} report={report} drawermodel={false}/>
                ))}
                {loading && <Typography variant='h5'>Fetching reports...</Typography>}
            </div>
        </>
    );
}
