import Constants from '../../utils/constants';
import serviceCall from '../../reducers/serviceCall';
import authHeader from '../../utils/authHeader';

const initialState = {
    reports: [],
    reportsHistory: [],
    total: 0,
    loading: false,
    patientReportBuffer: null
};

export function ClientsReports(state = initialState, action) {
    switch (action.type) {
        case 'fetchPatientsReports':
            return {
                ...state,
                reports: action.payload.reports,
                total: action.payload.count
            }
        case 'fetchPatientsReportsHistory':
            return {
                ...state,
                reportsHistory: action.payload.reports,
            }
        case 'loader':
            return {
                ...state,
                loading: action.payload
            };
        case 'downloadPatientReport':
            return {
                ...state,
                patientReportBuffer: action.payload
            };
        default:
            return {...state}
    }
}

export const toggleLoginLoader = (type) => ({
    type: 'loader',
    payload: type
});

export function fetchPatientsReports(pageSize, page, sortModel, filterData, site) {
    return (dispatch, getState) => {
        dispatch(toggleLoginLoader(true));
        serviceCall({
            url: Constants.API.PATIENTS_REPORT + '?pageSize=' + pageSize + '&page=' + page + '&' + sortModel + '&search=' + filterData + '&site=' + site,
            method: 'GET',
            headers: authHeader()
        }).then((data) => {
            dispatch(toggleLoginLoader(false));
            if (data.data.reports.length > 0) {
                dispatch({type: 'fetchPatientsReports', payload: data.data});
            } else {
                dispatch({type: 'fetchPatientsReports', payload: {reports: [], count: 0}});
            }
        }).catch((error) => {
            dispatch(toggleLoginLoader(false));
            dispatch({type: 'fetchPatientsReports', payload: {reports: [], count: 0}});
        });
    }
}

export function fetchPatientsReportsHistory(report = '') {
    return (dispatch, getState) => {
        dispatch(toggleLoginLoader(true));
        serviceCall({
            url: Constants.API.PATIENTS_REPORT + '?history=' + report + '&pageSize= &page=0&sort=asc&field=coldate&search=',
            method: 'GET',
            headers: authHeader()
        }).then((data) => {
            dispatch(toggleLoginLoader(false));
            if (data.data.reports.length > 0) {
                dispatch({type: 'fetchPatientsReportsHistory', payload: data.data});
            } else {
                dispatch({type: 'fetchPatientsReportsHistory', payload: {reports: []}});
            }
        }).catch((error) => {
            dispatch(toggleLoginLoader(false));
            dispatch({type: 'fetchPatientsReportsHistory', payload: {reports: []}});
        });
    }
}

export function downloadPatientReport(reportId) {
    return (dispatch, getState) => {
        serviceCall({
            url: Constants.API.VIEW_REPORT + '/' + reportId,
            method: 'GET',
            headers: authHeader(),
        }).then((data) => {
            dispatch({type: 'downloadPatientReport', payload: data.data});
        }).catch((error) => {
            dispatch({type: 'downloadPatientReport', payload: null});
        });
    }
}

export const getPatientsReports = state => state.clientsReports.reports;

export const getPatientsReportsHistory = state => state.clientsReports.reportsHistory;

export const getLoading = state => state.clientsReports.loading;

export const getPatientReportBuffer = state => state.clientsReports.patientReportBuffer;

export const getPatientsReportsTotal = state => state.clientsReports.total;