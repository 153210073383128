import React from "react";
import {Box, Stack} from './../../ui'
import {DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";

function QuickSearchToolbar() {
    return (
        <Box display="flex" justifyContent="flex-end" sx={{p:1}}>
            <GridToolbarQuickFilter />
        </Box>
    );
}

export default function DataGridTable(props) {
    const {filterMode, columns, reports, loading, pageSize, setPageSize, page, setPage, sortModel, setSortModel, setFilterData, total, paginationMode} = props

    return (
        <DataGrid
            loading={loading}
            rows={reports}
            rowCount={total??0}
            columns={columns}
            pageSize={pageSize}
            page={page}
            sortModel={sortModel}
            paginationMode={paginationMode}
            filterMode={filterMode}
            rowsPerPageOptions={[5, 10, 20]}
            onPageChange={(newPage) => setPage ? setPage(newPage) : ''}
            onSortModelChange={(newSortModel) => setSortModel ? setSortModel(newSortModel) : ''}
            onFilterModelChange={ (newFilterData) => setFilterData(newFilterData)}
            onPageSizeChange={(newPage) => setPageSize ? setPageSize(newPage) : ''}
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            components={{
                NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        No reports available
                    </Stack>
                ),
                NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        filter returns no reports
                    </Stack>
                ),
                Toolbar: setFilterData ? QuickSearchToolbar : '',
            }}
            sx={{
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                '&.MuiDataGrid-root': {
                    border: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                },
                backgroundColor: 'white'
            }}
        />
    )
}