import jwt_decode from "jwt-decode";

export function formatUserLoginId(userLoginId, mask = false) {
    if (!userLoginId || getRequestMethod(userLoginId) === 'email')
        return userLoginId;
    const formattedNumber = userLoginId.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

    if (!mask)
        return formattedNumber;

    return `${formattedNumber.slice(0, 9)}-****`
}

export function getRequestMethod(value) {
    const re = /\S+@\S+\.\S+/;
    return re.test(value) ? 'email' : 'phone';
}

export function parseJwt(token) {
    if (!token)
        return null;
    try {
        const userData = jwt_decode(token);
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        if (currentTimeInSeconds > userData.exp)
            return null;
        return userData;
    } catch (e) {
        return null;
    }
}

export function dateTimeFormat(dateString, isDate = false) {
    if (Date.parse(dateString)) {
        const dateTime = new Date(dateString)
        if (/:/.test(dateString) && !isDate) {
            //this formats date string into date [Jul 20, 2022, 5:30:00 AM]
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'medium', timeStyle: 'medium'}).format(dateTime)
        } else {
            //this formats date string into date [Jul 20, 2022]
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(dateTime)
        }
    } else {
        return '-'
    }
}

export function createUrlParams(obj) {
    let getPairs = (obj, keys = []) =>
        Object.entries(obj).reduce((pairs, [key, value]) => {
            if (typeof value === 'object')
                pairs.push(...getPairs(value, [...keys, key]));
            else
                pairs.push([[...keys, key], value]);
            return pairs;
        }, []);

    return getPairs(obj)
        .map(([[key0, ...keysRest], value]) =>
            `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
        .join('&')
}

export function createDateId(dateString) {
    if (Date.parse(dateString)) {
        const dateTime = new Date(dateString)
        return (dateTime.getMonth() + 1) + '' + dateTime.getDay() + '' + dateTime.getFullYear() + '' + dateTime.getHours() + '' + dateTime.getMinutes() + '' + dateTime.getSeconds();
    }
    return ''
}

export function isReportDownloadable(reportStatus) {
    //return if null
    if (!reportStatus) return false;

    const downloadableStatuses = ['download'];

    return downloadableStatuses.includes(reportStatus.toLowerCase());
}

export function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
}
