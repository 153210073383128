import styled from '@emotion/styled'
import {isMobile} from 'react-device-detect';

export const PatientsReportsWrapper = styled.div`
  .pageHeader {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 25px;
  }

  .pageHeaderText {
    margin-bottom: 32px;
    color: #7E7E7E;
  }
  
  .noRecordDiv{
    padding-bottom: 16px;
    padding-top: 16px;
  }
`;

export const PatientsReportsGridWrapper = styled.div`
  .labelTitle {
    color: #7E7E7E
  }

  .valueSubtitle {
    font-weight: bold
  }

  .downloadCardDiv {
    border-top: 1px dashed black;
    width: 100%;
    padding-top: 5px
  }

  .downloadCardDivInside {
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 8px
  }
`;

export const PatientsReportsHistoryWrapper = styled.div`
  .respIcon {
    font-size: ${isMobile ? '12px' : '13px'}
  }
  
  .noRecordDiv{
    padding-bottom: 16px;
    padding-top: 16px;
  }
`;

export const PatientsReportDownloadWrapper = styled.div`
  .downloadReportBtn {
    cursor: pointer;
    text-transform: capitalize;
    float: right;
  }

  .downloadReportLink {
    cursor: pointer
  }
`;