import {createDateId, dateTimeFormat, isReportDownloadable} from "../../utils";
import DownloadClientReport from "./downloadClientReport";

const columns = [
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
            if (isReportDownloadable(params.value)) {
                return (<>
                    <DownloadClientReport reportId={params.row.report_id}
                                          filename={`${params.row.bc}-${createDateId(params.row.reportedts)}`}
                                          isButton={false}/>
                </>);
            } else {
                return params.value;
            }
        },
        renderHeader: (params) => (<strong>Status</strong>)
    },
    {
        field: 'bc',
        headerName: 'Barcode',
        flex: 0.5,
        renderHeader: (params) => (<strong>Barcode</strong>)
    },
    {
        field: 'lname',
        headerName: 'Last Name',
        flex: 0.5,
        renderHeader: (params) => (<strong>Last Name</strong>)
    },
    {
        field: 'fname',
        headerName: 'First Name',
        flex: 0.5,
        renderHeader: (params) => (<strong>First Name</strong>)
    },
    {
        field: 'coldate',
        headerName: 'Collection Date',
        flex: 1,
        renderHeader: (params) => (<strong>Collection Date</strong>),
        valueFormatter: (params) => dateTimeFormat(params.value),
    },
    {
        field: 'reportedts',
        headerName: 'Report Date',
        flex: 1,
        renderHeader: (params) => (<strong>Report Date</strong>),
        valueFormatter: (params) => dateTimeFormat(params.value),
    },
    {
        field: 'result',
        headerName: 'Result',
        flex: 1,
        renderHeader: (params) => (<strong>Result</strong>),
        valueFormatter: (params) => params.value ? params.value : '-'
    },
];

export default columns