import {configureStore} from '@reduxjs/toolkit'
import {Login} from '../components/login/loginReducer';
import {PatientsReports} from '../components/patients-reports/patientsReportReducer';
import {ClientsReports} from "../components/clients-reports/clientsReportsReducer";

const store = configureStore({
    reducer: {
        login: Login,
        patientsReports: PatientsReports,
        clientsReports: ClientsReports,
    },
});

export default store;
