import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Global, css} from '@emotion/react'
import {useDispatch, useSelector} from 'react-redux';

import RequestOTP from "./requestOtp";
import ValidateOTP from "./validateOtp";
import {requestOtp, validateOtp, getLoading, getIsLoggedIn, getRequestOtpResponse, getLoginError} from './loginReducer';

import {Container, Box, Button, Divider, West} from "../../ui";
import Constants from '../../utils/constants';
import {getLocalData} from "../../utils/storageManager";
import toast from "../../utils/toast";
import FormValidation from "../../utils/formValidation";
import constants from "../../utils/constants";
import {LoginWrapper} from './style';

export default function Login() {
    /**
     * Start Initials
     */
    const [otpRequested, setOtpRequested] = useState(false);
    const [user, setUser] = useState(null);
    const [captchaResponse, setCaptchaResponse] = useState(null);
    const [errors, setErrors] = useState({})

    const [searchParams, _] = useSearchParams();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const otpResponse = useSelector(getRequestOtpResponse);
    const loading = useSelector(getLoading);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const loginError = useSelector(getLoginError);
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        if (loginError) {
            setErrors(loginError);
        }
    }, [loginError]);

    useEffect(() => {
        const params = searchParams.get("d");
        validateParamsAndRedirect(params)
    }, []);

    useEffect(() => {
        if (otpResponse)
            setOtpRequested(true);
    }, [otpResponse]);

    useEffect(() => {
        if (isLoggedIn && getLocalData('token'))
            navigate(Constants.ROUTES.REPORT);
    }, [isLoggedIn]);
    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    function validateParamsAndRedirect(params) {
        if (params) {
            try {
                const payload = JSON.parse(window.atob(params));
                if (payload.o && payload.u) {
                    dispatch(validateOtp({otp: payload.o, userLoginId: payload.u}));
                }
            } catch (e) {
                toast.error('Invalid url')
            }
        }
    }

    function updateUser(value, key) {
        if (!value || !key)
            return;
        const tempUser = user ? {...user} : {};
        if (key === 'userLoginId')
            tempUser[key] = value;
        else
            tempUser[key] = value;
        setUser(tempUser);
    }

    function onSubmitRequestOtp() {
        if (otpResponse)
            dispatch(validateOtp(user));
        else {
            dispatch(requestOtp({...user, captcha: captchaResponse}));
        }
    }

    function resendOtp() {
        dispatch(requestOtp(user));
    }

    function handleSubmit(e) {
        e.preventDefault()

        const validationResponse = FormValidation(user, otpResponse)
        if (Object.keys(validationResponse).length !== 0) {
            setErrors(validationResponse);
        } else {
            onSubmitRequestOtp()
        }
    }

    function goToBack() {
        window.location.href = constants.ROUTES.LOGIN
    }
    /**
     * End Methods
     */

    return (
        <Container maxWidth={false} id="container">
            <Global
                styles={css`
                  #container {
                    height: 100%;
                    background-color: #F4F4F4;
                    padding: unset;
                    margin: unset;
                  }
                `}
            />
            <LoginWrapper>
                <div className="logoWrapper">
                    <img className="logo" src="images/logo.png" alt=""/>
                </div>
                <Box className="formContainer" component="form" autoComplete="off" onSubmit={handleSubmit}>
                    {otpResponse ? <><Button variant="text" size="large" startIcon={<West/>} onClick={goToBack}
                                             sx={{p: 1}}/> <Divider/></> : ''}
                    <Box className="InnerContainer">
                        {otpRequested ?
                            <ValidateOTP loading={loading} user={user} updateUser={updateUser} resendOtp={resendOtp}
                                         errors={errors}/> :
                            <RequestOTP loading={loading} user={user} captchaResponse={captchaResponse}
                                        setCaptchaResponse={setCaptchaResponse} updateUser={updateUser}
                                        errors={errors}/>
                        }
                    </Box>
                </Box>
            </LoginWrapper>
        </Container>
    );
}