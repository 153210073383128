import Constants from '../../utils/constants';
import serviceCall from '../../reducers/serviceCall';
import authHeader from '../../utils/authHeader';
import toast from "../../utils/toast";

const initialState = {
    patientsReports: [],
    patientsReportsHistory: [],
    loading: false,
    patientReportBuffer: null
};

export function PatientsReports(state = initialState, action) {
    switch (action.type) {
        case 'fetchPatientsReports':
            return {
                ...state,
                patientsReports: action.payload
            }
        case 'fetchPatientsReportsHistory':
            return {
                ...state,
                patientsReportsHistory: action.payload
            }
        case 'loader':
            return {
                ...state,
                loading: action.payload
            };
        case 'downloadPatientReport':
            return {
                ...state,
                patientReportBuffer: action.payload
            };
        default:
            return {...state}
    }
}

export const toggleLoginLoader = (type) => ({
    type: 'loader',
    payload: type
});

export function fetchPatientsReports() {
    return (dispatch, getState) => {
        dispatch(toggleLoginLoader(true));
        serviceCall({
            url: Constants.API.REPORTS,
            method: 'GET',
            headers: authHeader()
        }).then((data) => {
            dispatch(toggleLoginLoader(false));
            if (data.data.length > 0) {
                dispatch({type: 'fetchPatientsReports', payload: data.data})
            } else {
                dispatch({type: 'fetchPatientsReports', payload: []})
            }
        }).catch((error) => {
            dispatch(toggleLoginLoader(false));
            dispatch({type: 'fetchPatientsReports', payload: []})

        });
    }
}

export function fetchPatientsReportsHistory(report='') {
    return (dispatch, getState) => {
        dispatch(toggleLoginLoader(true));
        serviceCall({
            url: Constants.API.REPORTS + '?history=' + report,
            method: 'GET',
            headers: authHeader()
        }).then((data) => {
            dispatch(toggleLoginLoader(false));
            if (data.data.length > 0) {
                dispatch({type: 'fetchPatientsReportsHistory', payload: data.data})
            } else {
                dispatch({type: 'fetchPatientsReportsHistory', payload: []})
            }
        }).catch((error) => {
            dispatch(toggleLoginLoader(false));
            dispatch({type: 'fetchPatientsReportsHistory', payload: []})

        });
    }
}

export function downloadPatientReport(reportId, orderId, search) {
    return (dispatch, getState) => {
        serviceCall({
            url: Constants.API.VIEW_REPORT + '/' + reportId + '?order_id='+ orderId +'&search=' + search,
            method: 'GET',
            headers: authHeader(),
        }).then((data) => {
            dispatch({type: 'downloadPatientReport', payload: data.data})
        }).catch((error) => {
            if(error.status === 422) {
                toast.error('Invalid patient identity')
            }
            if(error.status === 404) {
                toast.error('Report not found')
            }
        });
    }
}

export const getPatientsReports = state => state.patientsReports.patientsReports;

export const getPatientsReportsHistory = state => state.patientsReports.patientsReportsHistory;

export const getLoading = state => state.patientsReports.loading;

export const getPatientReportBuffer = state => state.patientsReports.patientReportBuffer;