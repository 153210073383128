import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux';
import {
    LoadingButton,
    TextField,
    InputAdornment,
    IconButton,
    VisibilityOff,
    Visibility,
} from "../../ui";
import {formatUserLoginId} from '../../utils'
import {clearLoginError} from './loginReducer';


export default function ValidateOTP({user, updateUser, loading, resendOtp, errors}) {
    /**
     * Start Initials
     */
    const [showResendOtp, setShowResendOtp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const dispatch=useDispatch();
    /**
     * End Initials
     */

    setTimeout(() => {
        setShowResendOtp(true);
    }, 60000);

    /**
     * Start Methods
     */
    /*function onClickResend(){
        resendOtp();
        setShowResendOtp(false);
        enableResendButton()
    }*/

    function enableResendButton() {
        setTimeout(() => {
            setShowResendOtp(true);
        }, 60000);
    }

    function onOtpInputChange(e){
        dispatch(clearLoginError());
        updateUser(e.target.value, 'otp', errors)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    /**
     * End Methods
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        enableResendButton();
    },[]);
    /**
     * End Life Cycle Methods
     */

    return (
        <>
            <div className="otpHeading">

                A secure code has been sent to:&nbsp;<span
                className="contactId">{formatUserLoginId(user.userLoginId).slice(0,6)}****</span>
            </div>
            <TextField
                onChange={onOtpInputChange}
                className="otpInput"
                required
                name='code'
                error={!!errors?.otp}
                helperText={errors?.otp}
                id="outlined-basic"
                label="Enter Verification Code"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                InputProps={{
                    endAdornment: <InputAdornment position="start">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                }}
            />
            <div className="resendOtp">
                {/*<div>Didn't receive the verification code?</div> <Button disabled={!showResendOtp} className="resendButton" onClick={onClickResend}> Resend </Button>*/}
            </div>
            <LoadingButton loading={loading} fullWidth disabled={!user.otp} type="submit" className="actionButton" variant="contained">
                Verify & Proceed
            </LoadingButton>
        </>
    )
}