import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import {styled, useTheme} from '@mui/material/styles';

import Constants from "../../../utils/constants";
import {
    Box,
    Menu,
    MenuItem,
    MuiAppBar,
    MuiDrawer,
    CssBaseline,
    IconButton,
    Toolbar,
    ChevronRightIcon,
    Typography,
    ChevronLeftIcon,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    LogoutIcon, BusinessIcon, ArticleIcon, MenuIcon, MedicalServicesIcon, Grid, CloseIcon, Tooltip
} from "../../../ui";
import {getUser, logOut} from "../../login/loginReducer";
import {NavbarWrapper} from "./style";
import {useLocation} from "react-router-dom";

const drawerWidth = isMobile ? '100%' : 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '0px 0px 0px -1px rgba(0,0,0,0.1),0px 1px 0px 0px rgba(0,0,0,0.1),0px 0px 1px 0px rgba(0,0,0,0.1) !important',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        boxShadow: '0px 0px 0px -1px rgba(0,0,0,0.1),0px 1px 0px 0px rgba(0,0,0,0.1),0px 0px 1px 0px rgba(0,0,0,0.1) !important',
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Navbar() {
    /**
     * Start Initials
     */
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();
    const userData = useSelector(getUser);

    const { pathname } = useLocation();
    /**
     * End Initials
     */

    /**
     * Start Methods
     */
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function logout() {
        dispatch(logOut());
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toolbarDivStyle = (isMobile ? {
        display: 'flex',
        width: '100%',
        alignSelf: 'flex-end',
        justifyContent: 'space-between'
    } : {display: 'flex'});

    const appBarStyle = (open && isMobile ? {display: 'none'} : {})

    const iconButtonStyle = ({
        alignSelf: 'center',
        marginRight: isMobile ? 0 : 5,
        ...(open && {display: 'none'}),
    })
    /**
     * End Methods
     */

    return (
        <NavbarWrapper>
            <CssBaseline/>
            <AppBar position="fixed" open={open} color="inherit" sx={appBarStyle}>
                <Toolbar className="nToolbar">
                    <div style={toolbarDivStyle}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={iconButtonStyle}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <a href={Constants.ROUTES.REPORT}>
                            <img src='logo.png' alt='Neelyx Labs' className="nLogo"/>
                        </a>
                        <div></div>
                    </div>

                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Typography variant="h2"
                                    className="nUsername">Welcome {userData?.userLoginId ? userData?.userLoginId.slice(0, 3) : ''}...</Typography>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : (isMobile ? <CloseIcon/> :
                            <ChevronLeftIcon/>)}
                    </IconButton>
                </DrawerHeader>
                {open ? <Divider/> : ''}
                <List>
                    <ListItem disablePadding sx={{display: 'block'}}>
                        <Tooltip disableHoverListener={open} title="My Reports" placement="right">
                            <ListItemButton
                                LinkComponent='a'
                                href={Constants.ROUTES.REPORT}
                                selected={pathname === Constants.ROUTES.REPORT}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ArticleIcon/>
                                </ListItemIcon>
                                <ListItemText primary="My Reports" sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                    {userData?.role === 'client' ? <ListItem disablePadding sx={{display: 'block'}}>
                        <Tooltip disableHoverListener={open} title="My Organizations Reports" placement="right">
                            <ListItemButton
                                LinkComponent='a'
                                href={Constants.ROUTES.PATIENTS_REPORT}
                                selected={pathname === Constants.ROUTES.PATIENTS_REPORT}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <BusinessIcon/>
                                </ListItemIcon>
                                <ListItemText primary="My Organizations Reports" sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem> : ''}
                    <ListItem disablePadding sx={{display: 'block'}}>
                        <Tooltip disableHoverListener={open} title="Create Requisition Request" placement="right">
                            <ListItemButton
                                LinkComponent='a'
                                href={Constants.ROUTES.REQUISITION}
                                selected={pathname === Constants.ROUTES.REQUISITION}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <MedicalServicesIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Create Requisition Request" sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem disablePadding sx={{display: 'block'}}>
                        <Tooltip disableHoverListener={open} title="Logout" placement="right">
                            <ListItemButton
                                onClick={logout}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary='Logout' sx={{opacity: open ? 1 : 0}}/>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </List>
                {open ? <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    className="nFooterGrid"
                >
                    <List>
                        <ListItem disablePadding className="nFooterListItem">
                            <Grid>
                                <Typography fontSize={14}>{Constants.COPYRIGHT}</Typography>
                                {/* <Typography sx={{
                                    fontSize: '10px'
                                }}>{`${process.env?.REACT_APP_BRANCH_NAME ? process.env?.REACT_APP_BRANCH_NAME + ":" : ""}${process.env?.REACT_APP_SHORT_SHA || ""}`}</Typography>*/}
                            </Grid>
                        </ListItem>
                    </List>
                </Grid> : ''}
            </Drawer>
        </NavbarWrapper>
    );
}
