import { getLocalData } from "./storageManager";

/**
 *
 * @returns {{"auth-token"}|{}}
 */
export default function authHeader() {
    const token = getLocalData('token');
    if (token) {
      // for Node.js Express back-end
      return { 'auth-token': token };
    } else {
      return {};
    }
  }