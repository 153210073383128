import React from 'react';
import {isMobile, MobileView, BrowserView} from 'react-device-detect';

import {Box, Container, Typography} from './../../ui'
import Navbar from "../common/navbar";
import {styled} from "@mui/material/styles";
import ClientsReportsBrowserView from "./clientsReportsBrowserView";
import ClientsReportsMobileView from "./clientsReportsMobileView";
import {ClientsReportsWrapper} from "./style";
import {useSelector} from "react-redux";
import {getIsLoggedIn} from "../login/loginReducer";
import Constants from "../../utils/constants";

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const sortModelDefault = {
    field: 'coldate',
    sort: 'desc',
}

export default function ClientsReports() {
    /**
     * Start Initials
     */
    const isLoggedIn = useSelector(getIsLoggedIn);
    if(!isLoggedIn)
        window.location.href = Constants.ROUTES.LOGIN;
    /**
     * End Initials
     */

    return (
        <Box sx={isMobile ? {} : {display: 'flex'}}>
            <Navbar/>

            <Box component="main" sx={isMobile ? {flexGrow: 1, mt: 9} : {flexGrow: 1, p: 3}}>
                {isMobile ? '' : <DrawerHeader/>}
                <ClientsReportsWrapper>
                    <Container>
                        <Typography variant='h2' display="block" className="pageHeader">
                            My Organization’s Reports
                        </Typography>

                        <BrowserView>
                            <ClientsReportsBrowserView sortModelDefault={sortModelDefault}/>
                        </BrowserView>

                        <MobileView>
                            <ClientsReportsMobileView sortModelDefault={sortModelDefault}/>
                        </MobileView>
                    </Container>
                </ClientsReportsWrapper>
            </Box>
        </Box>
    );
}
