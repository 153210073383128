import React from "react";
import {Box, Typography, Paper, Grid} from './../../ui'
import DownloadPatientReport from "./downloadPatientReport";
import {dateTimeFormat, isReportDownloadable} from "../../utils";
import ViewPatientReportHistory from "./viewPatientReportHistory";
import {PatientsReportsGridWrapper} from "./style";

export default function PatientsReportsGrid(props) {
    /**
     * Start Initials
     */
    const {report, drawermodel} = props
    /**
     * End Initials
     */

    return (
        <Box sx={{flexGrow: 1, mb: 7}}>
            <Paper sx={{p: 1.5}}>
                <PatientsReportsGridWrapper>
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" display="block" className='labelTitle'>
                                    Barcode
                                </Typography>
                                <Typography variant="subtitle2" className='valueSubtitle'>
                                    {report.bc}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" display="block" className='labelTitle'>
                                    Initials
                                </Typography>
                                <Typography variant="subtitle2" className='valueSubtitle'>
                                    {report.lname}, {report.fname}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" display="block" className='labelTitle'>
                                    {report.status === 'Download' ? "Reported Date" : 'Collection Date'}
                                </Typography>
                                <Typography variant="subtitle2" className='valueSubtitle'>
                                    {report.status === 'Download' ?
                                        (Date.parse(report.reportedts) ? dateTimeFormat(new Date(report.reportedts), true) : '') :
                                        (Date.parse(report.coldate) ?
                                            dateTimeFormat(new Date(report.coldate), true) :
                                            '')
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sx={{mt: 1}}>
                        <div className='downloadCardDiv'>
                            {isReportDownloadable(report.status) ?
                                <>
                                    <div className='downloadCardDivInside'>
                                        <DownloadPatientReport
                                            reportId={report.report_id}
                                            orderId={report.order_id}
                                            isButton={true}
                                        />
                                    </div>
                                    {(!drawermodel && report.is_corrected) && <ViewPatientReportHistory report={report.bc}/>}
                                </> :
                                <Typography ariant="subtitle2"
                                            className='downloadCardDivInside'>{report.status}</Typography>}
                        </div>
                    </Grid>
                </PatientsReportsGridWrapper>
            </Paper>
        </Box>
    )
}