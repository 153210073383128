import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import DataGridTable from "../common/dataGridTable";
import {fetchPatientsReports, getPatientsReports, getLoading, getPatientsReportsTotal} from "./clientsReportsReducer";
import {createUrlParams} from "../../utils";
import {Box, Stack, TextField} from "../../ui";
import {getLocalData} from "../../utils/storageManager";
import {Autocomplete} from "@mui/lab";
import clientsReportsColumns from "./clientsReportsColumns";

export default function ClientsReportsBrowserView(props) {
    /**
     * Start Initials
     */
    const {sortModelDefault, report} = props

    const [sitesList, setSitesList] = useState([])
    const [site, setSite] = useState('')
    const [pageSize, setPageSize] = useState(5)
    const [page, setPage] = useState(0)
    const [filterData, setFilterData] = useState({quickFilterValues: []})
    const [sortModel, setSortModel] = useState([{
        field: 'coldate',
        sort: 'desc',
    }]);

    const dispatch = useDispatch();
    //used to get reports from getReports reducer action
    const reports = useSelector(getPatientsReports);
    //used to get loading type (data fetching done or not)
    const loading = useSelector(getLoading);
    const total = useSelector(getPatientsReportsTotal);
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    // function used to dispatch fetchReports on page load
    useEffect(() => {
        getSites();
    }, []);


    useEffect(() => {
        if (site) processReports();
    }, [pageSize, page, sortModel, filterData, site]);

    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    function getSites() {
        try {
            const sitesData = JSON.parse(getLocalData('sites'));
            let siteArr = [];
            sitesData.forEach((v)=>{
                siteArr.push(v);
            });
            setSitesList(siteArr);
            if(siteArr.length > 0)
                setSite(siteArr[0] ?? '');
        } catch {
        }
    }

    function processReports() {
        dispatch(fetchPatientsReports(pageSize, page, createUrlParams(sortModel[0] ? sortModel[0] : sortModelDefault), filterData.quickFilterValues[0] ?? '', site, report));
    }

    /**
     * End Methods
     */

    return (
        <Box sx={{width: '100%'}}>
            <Stack direction="row" spacing={1} sx={{mb: 1}}>
                <Autocomplete
                    disableClearable
                    autoSelect
                    options={sitesList}
                    value={site}
                    onChange={(event, newValue) => {
                        setSite(newValue)
                        setPage(0)
                    }}
                    sx={{width: 300}}
                    renderInput={(params) => <TextField {...params} label="Sites"/>}
                />
            </Stack>
            <DataGridTable
                columns={clientsReportsColumns}
                reports={reports}
                total={total}
                loading={loading}
                pageSize={pageSize}
                setPageSize={setPageSize}
                page={page}
                setPage={setPage}
                sortModel={sortModel}
                setSortModel={setSortModel}
                setFilterData={setFilterData}
                paginationMode='server'
            />
        </Box>
    );
}
