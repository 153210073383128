import styled from '@emotion/styled'
import {isMobile} from 'react-device-detect';

export const ClientsReportsWrapper = styled.div`
  .pageHeader{
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 25px;
  }

  .searchInput{
    background: white;
    margin-bottom: 24px
  }  
  
  .respIcon{
    font-size: ${isMobile ? '12px' : '13px'}
  }
  
  .downloadReportBtn{
    cursor: pointer;
    text-transform: capitalize;
    float: right;
  }
  
  .downloadReportLink{
    cursor: pointer
  }
`;

export const ClientsReportsGridWrapper = styled.div`
  .subtitleResult{
    font-weight: bold;
    margin-top: 13.6px;
    margin-bottom: 8px
  }

  .labelTitle{
    color: #7E7E7E
  }
  .valueSubtitle{
    font-weight: bold
  }

  .subStatusTitle{
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px
  }

  .downloadCardDiv{
    border-top: 1px dashed black;
    width: 100%;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
  }
  .downloadCardDivInside{
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 8px
  }
`;

export const ClientsReportsHistoryWrapper = styled.div`
  .viewHistoryBtn{
    margin-left:8px;
    margin-top: ${isMobile ? '8px' : 0}
  }
  
  .respIcon{
    font-size: ${isMobile ? '12px' : '13px'}
  }
  
  .mobileScrollDiv{
    overflow-y: scroll;
    padding-bottom: 15px;
    max-height: ${window.innerHeight - 150}px
  }
`;