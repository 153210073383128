import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import {styled} from "@mui/material/styles";

import {Box, Typography, Container, Paper} from './../../ui'

import {getLoading, getPatientsReports, fetchPatientsReports} from './patientsReportReducer';
import Navbar from '../common/navbar';

import PatientsReportsGrid from "./patientsReportsGrid";
import DataGridTable from "../common/dataGridTable";
import patientsReportsTableColumns from "./patientsReportsTableColumns";
import {PatientsReportsWrapper} from "./style";
import {getIsLoggedIn} from "../login/loginReducer";
import Constants from "../../utils/constants";

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function PatientsReports() {
    /**
     * Start Initials
     */
    const [pageSize, setPageSize] = useState(5)

    const dispatch = useDispatch();
    const patientsReports = useSelector(getPatientsReports);
    const loading = useSelector(getLoading);
    const isLoggedIn = useSelector(getIsLoggedIn);
    if(!isLoggedIn)
        window.location.href = Constants.ROUTES.LOGIN;
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        dispatch(fetchPatientsReports())
    }, []);
    /**
     * End Life Cycle Methods
     */

    return (
        <Box sx={isMobile ? {} : {display: 'flex'}}>
            <Navbar/>

            <Box component="main" sx={isMobile ? {flexGrow: 1, mt: 12} : {flexGrow: 1, p: 3}}>
                {isMobile ? '' : <DrawerHeader/>}
                <PatientsReportsWrapper>
                    <Container>
                        <Typography variant='h2' className="pageHeader" display="block">
                            My Reports
                        </Typography>
                        <Typography variant="subtitle2" display="block" className='pageHeaderText'>
                            To protect patient privacy, only the first letter of the last name and the first two letters
                            of
                            the first name are shown.
                        </Typography>

                        <BrowserView>
                            <DataGridTable
                                columns={patientsReportsTableColumns}
                                reports={patientsReports}
                                loading={loading}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                total={patientsReports.length}
                                paginationMode='client'
                                filterMode='client'
                                drawermodel={0}
                            />
                        </BrowserView>

                        <MobileView>
                            {patientsReports.length > 0 ?
                                patientsReports.map(report => <PatientsReportsGrid key={report.id} report={report}/>) :
                                <Paper>
                                    <Typography textAlign='center' className="noRecordDiv" drawermodel={0}>
                                        No record found
                                    </Typography>
                                </Paper>
                            }
                        </MobileView>
                    </Container>
                </PatientsReportsWrapper>
            </Box>
        </Box>
    );
}
