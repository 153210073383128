import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    TextField,
} from '../../ui';

export default function VerifyIdentityDialog(props) {
    /**
     * Start Initials
     */
    const {dialog, setDialog, setSearch, submitData, setDateValue, setNameValue, dateValue, nameValue} = props
    /**
     * End Initials
     */

    /**
     * Start Methods
     */
    const handleClose = () => {
        setDialog(false);
        setDateValue(null);
        setNameValue('');
    };

    function handleDateChange(newDateValue) {
        setDateValue(newDateValue)
        setNameValue('')

        if(newDateValue) {
            setSearch(new Intl.DateTimeFormat('en-US').format(newDateValue.$d))
        }else{
            setSearch(newDateValue)
        }
    }

    function handleNameChange(e) {
        setDateValue(null)
        setNameValue(e.target.value)
        setSearch(e.target.value)
    }
    /**
     * End Methods
     */

    return (
        <Dialog
            open={dialog}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Verify your identity to download report
            </DialogTitle>
            <DialogContent>
                <TextField value={nameValue} onChange={handleNameChange} fullWidth label="First Name or Last Name" variant="outlined" sx={{mt: 1}}/>
                <Divider sx={{my: 1}}>OR</Divider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {isMobile ? <MobileDatePicker
                            label="Date Of Birth"
                            inputFormat="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            value={dateValue}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        /> :
                        <DesktopDatePicker
                            label="Date Of Birth"
                            inputFormat="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            value={dateValue}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField error={false} fullWidth {...params} />}
                        />}
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="error">
                    Cancel
                </Button>
                <Button onClick={submitData} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
