import React, {useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import {Box, Button, Modal, Stack} from "../../ui";
import {isMobile} from "react-device-detect";

const style = {
    margin: 'auto',
    height: '100%',
    bgcolor: 'background.paper',
    border: '2px solid rgba(224, 224, 224, 1)',
    boxShadow: 24,
    p: 2,
};

const pdfStyle = isMobile ? {
        overflow: 'scroll',
        width: `${window.innerWidth}px`,
        margin: 'auto',
        height: '95%',
        border: '2px solid rgba(224, 224, 224, 1)',
    } :
    {
        overflow: 'scroll',
        width: '100%',
        margin: 'auto',
        height: '95%',
        border: '2px solid rgba(224, 224, 224, 1)',
    };

function PdfViewer(props) {
    /**
     * Start Initials
     */
    const {pdf, fileName, openModal, setOpenModal} = props;

    const [numPages, setNumPages] = useState(null);
    /**
     * End Initials
     */

    /**
     * Start Methods
     */
    const handleClose = () => setOpenModal(false);

    const onclickDownload = () => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = linkSource;
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
    }

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    }
    /**
     * End Methods
     */

    return (
        <Modal
            hideBackdrop
            open={openModal}
            onClose={handleClose}
            sx={{left: 'auto'}}
        >
            <Box sx={style}>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{mb: 1}}
                >
                    <Button variant="contained" color="primary" onClick={onclickDownload}>Download</Button>
                    <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
                </Stack>
                <Box style={pdfStyle}>
                    <Document file={`data:application/pdf;base64,${pdf}`} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                />
                            ),
                        )}
                    </Document>
                </Box>
            </Box>
        </Modal>
    )
}

export default PdfViewer;