import React from "react";

import {Box, Typography, Paper, Grid} from './../../ui'
import DownloadClientReport from "./downloadClientReport";
import {dateTimeFormat, isReportDownloadable, createDateId} from "../../utils";
import ViewClientReportHistory from "./viewClientReportHistory";
import {ClientsReportsGridWrapper} from "./style";

export default function ClientsReportsGrid(props) {
    /**
     * Start Initials
     */
    const {report, drawermodel} = props
    /**
     * End Initials
     */

    return (
        <ClientsReportsGridWrapper>
            <Box sx={{flexGrow: 1, mb: 7}}>
                <Paper sx={{p: 1.5}}>
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" display="block"
                                            className="labelTitle">Barcode</Typography>
                                <Typography variant="subtitle2" className="valueSubtitle">{report.bc}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" display="block"
                                            className="labelTitle">Initials</Typography>
                                <Typography variant="subtitle2"
                                            className="valueSubtitle">{report.lname}, {report.fname}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" display="block" className="labelTitle">
                                    {report.status === 'Download' ? "Reported Date" : 'Collection Date'}
                                </Typography>
                                <Typography variant="subtitle2" className="valueSubtitle">
                                    {report.status === 'Download' ?
                                        (Date.parse(report.reportedts) ?
                                            dateTimeFormat(new Date(report.reportedts), true) : '') :
                                        (Date.parse(report.coldate) ?
                                            dateTimeFormat(new Date(report.coldate), true) :
                                            '')
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item sx={{mt: 1}}>
                        <div className="downloadCardDiv">
                            {isReportDownloadable(report.status) ?
                                <><Typography ariant="subtitle2" className="subtitleResult">{report.result}</Typography>
                                    {(!drawermodel && report.is_corrected) && <ViewClientReportHistory report={report.bc}/>}
                                    <div className="downloadCardDivInside">
                                        <DownloadClientReport reportId={report.report_id} isButton={true}
                                                              filename={`${report.bc}-${createDateId(report.reportedts)}`}/>
                                    </div>
                                </>
                                :
                                <Typography ariant="subtitle2" className="subStatusTitle">{report.status}</Typography>}
                        </div>
                    </Grid>
                </Paper>
            </Box>
        </ClientsReportsGridWrapper>
    )
}