import Constants from '../../utils/constants';
import serviceCall from '../../reducers/serviceCall';
import {setLocalData, getLocalData, removeLocalData} from "../../utils/storageManager";
import {parseJwt} from "../../utils";
import toast from "../../utils/toast";

const token = getLocalData('token');
const user = parseJwt(token);
if(!user && token){
    removeLocalData('token');
}

const initialState = {
    user: user,
    otpResponse: false,
    loading: false,
    error: null,
    isLoggedIn: !!user
};

export function Login(state = initialState, action) {
    switch (action.type) {
        case 'loader':
            return {
                ...state, loading: action.payload
            };
        case 'login/requestOtp':
            return {
                ...state, error: null, otpResponse: action.payload
            };

        case 'login/validateOtp':
            setLocalData('token',action.payload.token);
            setLocalData('sites',JSON.stringify(action.payload.sites));
            const updatedState = {
                error: null,
                isLoggedIn: true,
                user: parseJwt(action.payload.token)
            };
            return {
                ...state, ...updatedState
            };

        case 'logout': {
            removeLocalData('token');
            window.location.href = Constants.ROUTES.LOGIN;
            return {
                user: null,
                otpResponse: false,
                loading: false,
                error: null,
                isLoggedIn: false
            };
        }

        case 'login/error':
            return {
                ...state,
                error: action.payload
            };

        default:
            return {...state}
    }
}

export const toggleLoginLoader = (type) => ({
    type: 'loader',
    payload: type
});

export function requestOtp(user) {
    return (dispatch, getState) => {
        dispatch(toggleLoginLoader(true));
        dispatch({type: 'login/error', payload: ''});
        const data = {
            userLoginId: user?.userLoginId?.toLowerCase(),
            captcha: user.captcha
        };
        serviceCall({
            url: Constants.API.REQUEST_OTP,
            method: 'POST',
            data: data,
        }).then((data) => {
            window.grecaptcha?.enterprise?.reset();
            dispatch(toggleLoginLoader(false));
            dispatch({type: 'login/requestOtp', payload: true})
        }).catch((error) => {
            window.grecaptcha?.enterprise?.reset();
            dispatch(toggleLoginLoader(false));
            if(error.status === 404){
                dispatch({type: 'login/error', payload: {userLoginId:'User not found'}});
            }
            else if(error.status === 400){
                dispatch({type: 'login/error', payload: {userLoginId:'User not found'}});
            } else{
                toast.error("Server error");
            }
        });
    }
}

export function validateOtp(user) {
    return (dispatch, getState) => {
        dispatch(toggleLoginLoader(true));
        dispatch({type: 'login/error', payload: ''});
        const data = {
            userLoginId: user?.userLoginId?.toLowerCase(),
            otp: user.otp
        };
        serviceCall({
            url: Constants.API.LOGIN,
            method: 'POST',
            data: data,
        }).then((res) => {
            dispatch(toggleLoginLoader(false));
            dispatch({type: 'login/validateOtp', payload: res.data})
        }).catch((error) => {
            dispatch(toggleLoginLoader(false));
            if(error.status === 400){
                dispatch({type: 'login/error', payload: {otp:'Invalid verification code'}})
            }else{
                toast.error("Server error");
            }
        });
    }
}

export function logOut() {
    return (dispatch, getState) => {
        dispatch({type: 'logout'})
    }
}
export function clearLoginError() {
    return (dispatch, getState) => {
        dispatch({type: 'login/error',payload:null})
    }
}
export const getRequestOtpResponse = state => state.login.otpResponse;
export const getLoading = state => state.login.loading;
export const getIsLoggedIn = state => state.login.isLoggedIn;
export const getLoginError = state => state.login.error;
export const getUser = state => state.login.user ?? {};