import {createDateId, dateTimeFormat, isReportDownloadable} from "../../utils";
import DownloadPatientReport from "./downloadPatientReport";

const patientsReportsTableColumns = [
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
            if (isReportDownloadable(params.value)) {
                return (<>
                    <DownloadPatientReport
                        reportId={params.row.report_id}
                        orderId={params.row.order_id}
                        filename={`${params.row.bc}-${createDateId(params.row.reportedts)}`}
                        isButton={false}
                    />
                </>);
            } else {
                return params.value;
            }
        },
        renderHeader: (params) => (<strong>Status</strong>)
    },
    {
        field: 'bc',
        headerName: 'Barcode',
        flex: 0.5,
        renderHeader: (params) => (<strong>Barcode</strong>)
    },
    {
        field: 'lname',
        headerName: 'Last Name',
        flex: 0.5,
        renderHeader: (params) => (<strong>Last Initials</strong>)
    },
    {
        field: 'fname',
        headerName: 'First Name',
        flex: 0.5,
        renderHeader: (params) => (<strong>First Initials</strong>)
    },
    {
        field: 'coldate',
        headerName: 'Collection Date',
        flex: 1,
        renderHeader: (params) => (<strong>Collection Date</strong>),
        valueFormatter: (params) => dateTimeFormat(params.value),
    },
    {
        field: 'reportedts',
        headerName: 'Report Date',
        flex: 1,
        renderHeader: (params) => (<strong>Report Date</strong>),
        valueFormatter: (params) => dateTimeFormat(params.value),
    },
];

export default patientsReportsTableColumns