import axios from 'axios';
import { v4 as uuid } from 'uuid';
import toast from '../utils/toast';
import {removeLocalData} from "../utils/storageManager";
import constants from "../utils/constants";

export default function serviceCall(requestData) {
    let axiosRequestObject = {};
    axiosRequestObject = new Promise((resolve, reject) => {
        requestData.params = requestData.params || {};
        const request = {
            url: `${process.env.REACT_APP_API_BASE_URL}${requestData.url}`,
            method: requestData.method || 'GET',
            headers: requestData.headers || {},
            params: requestData.params,
            data: JSON.stringify(requestData.data || {})
        };
        request.headers['Content-Type'] = 'application/json';
        request.headers['correlation-id'] = uuid();

        axios(request)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                if(error.code === 'ERR_NETWORK'){
                    //server down error
                    toast.error("Server down")
                } else if(error.code === 'ERR_BAD_RESPONSE'){
                    //internal server error
                    toast.error("Internal server error")
                } else if(error?.response?.status === 401){
                    //internal server error
                    toast.error("Session expired")
                    removeLocalData('token')
                    window.location.href = constants.ROUTES.LOGIN
                }
                reject(error.response ? error.response : error);
            });
    });
    return axiosRequestObject;
}