function getLocalData(key) {
    const token = localStorage.getItem(key)
    if(!token){
        return null
    }
    return token
}

function setLocalData(key, value) {
    localStorage.setItem(key, value)
}

function removeLocalData(key) {
    localStorage.removeItem(key)
}

export {
    getLocalData,
    setLocalData,
    removeLocalData
}