import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";
import {styled} from "@mui/material/styles";

import Navbar from '../common/navbar';
import {Box, Container} from "../../ui";
import serviceCall from "../../reducers/serviceCall";
import Constants from "../../utils/constants";
import {createUrlParams} from "../../utils";
import authHeader from "../../utils/authHeader";
import {useSelector} from "react-redux";
import {getIsLoggedIn} from "../login/loginReducer";

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


export default function Requisition() {
    /**
     * Start Initials
     */
    const [url, setUrl] = useState(null);

    const isLoggedIn = useSelector(getIsLoggedIn);
    if(!isLoggedIn)
        window.location.href = Constants.ROUTES.LOGIN;
    /**
     * End Initials
     */

    /**
     * Start Life Cycle Methods
     */
    useEffect(() => {
        try {
            getPatient()
        } catch (e) {}
    }, []);
    /**
     * End Life Cycle Methods
     */

    /**
     * Start Methods
     */
    async function getPatient() {
        let urlParams

        //Get patient details
        const patientData = await serviceCall({
            url: Constants.API.PATIENT,
            method: 'GET',
            headers: authHeader()
        }).then(res => res.data);

        if (patientData) {
            //create object from patient details and create url params
            urlParams = createUrlParams({
                first: patientData.fname ?? '',
                last: patientData.lname ?? '',
                email: patientData.email ?? '',
                phone: patientData.phone ?? '',
                dob: patientData.dob ?? '',
                addr_line1: patientData.address ? patientData.address?.address_line1 ?? '' : '',
                addr_line2: patientData.address ? patientData.address?.address_line2 ?? '' : '',
                city: patientData.address ? patientData.address?.city ?? '' : '',
                state: patientData.address ? patientData.address?.state ?? '' : '',
                postal: patientData.address ? patientData.address?.zip_code ?? '' : '',
                sex: patientData.sex ?? '',
                race: patientData.race ?? '',
                ethnicity: patientData?.ethnicity ?? '',
            })
        }

        //append url params with iframe src
        setUrl(urlParams)
    }
    /**
     * End Methods
     */

    return (
        <Box sx={isMobile ? {} : {display: 'flex'}}>
            <Navbar/>

            <Box component="main" sx={isMobile ? {flexGrow: 1, mt: 14} : {flexGrow: 1, p: 3}}>
                {isMobile ? '' : <DrawerHeader/>}

                <Container>
                    <Box>
                        <iframe
                            src={`Patient_COVID-19_Requisition_Form.html?${url}`}
                            onLoad={window.parent.scrollTo(0,0)}
                            allowtransparency="true"
                            frameBorder="0"
                            scrolling="no"
                            style={{
                                position: 'absolute',
                                border: 'none',
                                top: '10%',
                                right: 0,
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                height: '4000px',
                                background: '#F4F4F4',
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
